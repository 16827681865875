import Vue from 'vue'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from './components/nuxt-error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_swplugin_0ececc76 from 'nuxt_plugin_swplugin_0ececc76' // Source: ./sw.plugin.js (mode: 'client')
import nuxt_plugin_onesignal_484fe776 from 'nuxt_plugin_onesignal_484fe776' // Source: ./onesignal.js (mode: 'client')
import nuxt_plugin_sentryserver_7b4e1021 from 'nuxt_plugin_sentryserver_7b4e1021' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_15949e99 from 'nuxt_plugin_sentryclient_15949e99' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_googleanalytics_1c1962ae from 'nuxt_plugin_googleanalytics_1c1962ae' // Source: ./google-analytics.js (mode: 'client')
import nuxt_plugin_moment_7c03804a from 'nuxt_plugin_moment_7c03804a' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_router_679f39f2 from 'nuxt_plugin_router_679f39f2' // Source: ./router.js (mode: 'all')
import nuxt_plugin_toast_3b056be8 from 'nuxt_plugin_toast_3b056be8' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_axios_164cfd5d from 'nuxt_plugin_axios_164cfd5d' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_prototypesclient_020776b6 from 'nuxt_plugin_prototypesclient_020776b6' // Source: ../plugins/prototypes.client.js (mode: 'client')
import nuxt_plugin_prototypes_13507910 from 'nuxt_plugin_prototypes_13507910' // Source: ../plugins/prototypes.js (mode: 'all')
import nuxt_plugin_vuexpersist_25bdab30 from 'nuxt_plugin_vuexpersist_25bdab30' // Source: ../plugins/vuex-persist.js (mode: 'client')
import nuxt_plugin_axios_5659d192 from 'nuxt_plugin_axios_5659d192' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_api_785206da from 'nuxt_plugin_api_785206da' // Source: ../plugins/api.js (mode: 'all')
import nuxt_plugin_components_50cb0b6b from 'nuxt_plugin_components_50cb0b6b' // Source: ../plugins/components.js (mode: 'all')
import nuxt_plugin_componentsclient_4637342c from 'nuxt_plugin_componentsclient_4637342c' // Source: ../plugins/components.client.js (mode: 'client')
import nuxt_plugin_filters_2dd71012 from 'nuxt_plugin_filters_2dd71012' // Source: ../plugins/filters.js (mode: 'all')
import nuxt_plugin_mixins_9ca86422 from 'nuxt_plugin_mixins_9ca86422' // Source: ../plugins/mixins.js (mode: 'all')
import nuxt_plugin_directives_521c0486 from 'nuxt_plugin_directives_521c0486' // Source: ../plugins/directives.js (mode: 'all')
import nuxt_plugin_directivesclient_62899358 from 'nuxt_plugin_directivesclient_62899358' // Source: ../plugins/directives.client.js (mode: 'client')
import nuxt_plugin_i18n_1fba523a from 'nuxt_plugin_i18n_1fba523a' // Source: ../plugins/i18n.js (mode: 'all')
import nuxt_plugin_plugin_ac745e4e from 'nuxt_plugin_plugin_ac745e4e' // Source: ./auth/plugin.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  const registerModule = store.registerModule
  store.registerModule = (path, rawModule, options) => registerModule.call(store, path, rawModule, Object.assign({ preserveState: process.client }, options))

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"htmlAttrs":{"lang":"ro"},"title":"Yummy.ro","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"mobile-web-app-capable","name":"mobile-web-app-capable","content":"yes"},{"hid":"apple-mobile-web-app-title","name":"apple-mobile-web-app-title","content":"smartapp-client"},{"hid":"author","name":"author","content":"Keresztesi Attila"},{"hid":"description","name":"description","content":"Smartapp Client"},{"hid":"theme-color","name":"theme-color","content":"#fff"},{"hid":"og:type","name":"og:type","property":"og:type","content":"website"},{"hid":"og:title","name":"og:title","property":"og:title","content":"smartapp-client"},{"hid":"og:site_name","name":"og:site_name","property":"og:site_name","content":"smartapp-client"},{"hid":"og:description","name":"og:description","property":"og:description","content":"Smartapp Client"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss?family=Roboto:400,500,700&display=swap"},{"rel":"manifest","href":"\u002F_nuxt\u002Fmanifest.cdb4d72a.json"}],"style":[],"script":[{"async":true,"src":"https:\u002F\u002Fcdn.onesignal.com\u002Fsdks\u002FOneSignalSDK.js"}]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.client && typeof nuxt_plugin_swplugin_0ececc76 === 'function') {
    await nuxt_plugin_swplugin_0ececc76(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_onesignal_484fe776 === 'function') {
    await nuxt_plugin_onesignal_484fe776(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_7b4e1021 === 'function') {
    await nuxt_plugin_sentryserver_7b4e1021(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_15949e99 === 'function') {
    await nuxt_plugin_sentryclient_15949e99(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics_1c1962ae === 'function') {
    await nuxt_plugin_googleanalytics_1c1962ae(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_7c03804a === 'function') {
    await nuxt_plugin_moment_7c03804a(app.context, inject)
  }

  if (typeof nuxt_plugin_router_679f39f2 === 'function') {
    await nuxt_plugin_router_679f39f2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_3b056be8 === 'function') {
    await nuxt_plugin_toast_3b056be8(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_164cfd5d === 'function') {
    await nuxt_plugin_axios_164cfd5d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_prototypesclient_020776b6 === 'function') {
    await nuxt_plugin_prototypesclient_020776b6(app.context, inject)
  }

  if (typeof nuxt_plugin_prototypes_13507910 === 'function') {
    await nuxt_plugin_prototypes_13507910(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuexpersist_25bdab30 === 'function') {
    await nuxt_plugin_vuexpersist_25bdab30(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5659d192 === 'function') {
    await nuxt_plugin_axios_5659d192(app.context, inject)
  }

  if (typeof nuxt_plugin_api_785206da === 'function') {
    await nuxt_plugin_api_785206da(app.context, inject)
  }

  if (typeof nuxt_plugin_components_50cb0b6b === 'function') {
    await nuxt_plugin_components_50cb0b6b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_componentsclient_4637342c === 'function') {
    await nuxt_plugin_componentsclient_4637342c(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_2dd71012 === 'function') {
    await nuxt_plugin_filters_2dd71012(app.context, inject)
  }

  if (typeof nuxt_plugin_mixins_9ca86422 === 'function') {
    await nuxt_plugin_mixins_9ca86422(app.context, inject)
  }

  if (typeof nuxt_plugin_directives_521c0486 === 'function') {
    await nuxt_plugin_directives_521c0486(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_directivesclient_62899358 === 'function') {
    await nuxt_plugin_directivesclient_62899358(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_1fba523a === 'function') {
    await nuxt_plugin_i18n_1fba523a(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_ac745e4e === 'function') {
    await nuxt_plugin_plugin_ac745e4e(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, (err) => {
        // https://github.com/vuejs/vue-router/blob/v3.3.4/src/history/errors.js
        if (!err._isRouter) return reject(err)
        if (err.type !== 1 /* NavigationFailureType.redirected */) return resolve()

        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
