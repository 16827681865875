window.$OneSignal = window.OneSignal = window.OneSignal || [];

OneSignal.push(['init', {
  "appId": "6063accd-1f86-44de-a448-02874020cc19",
  "safari_web_id": "web.onesignal.auto.1a94b592-c98a-427e-9490-520bfcd23754",
  "allowLocalhostAsSecureOrigin": false,
  "autoRegister": false,
  "welcomeNotification": {
    "title": "Welcome!",
    "message": "Thanks for subscribing!",
    "disable": true
  }
}]);

export default function (ctx, inject) {
  inject('OneSignal', OneSignal)
}
